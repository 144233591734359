// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("@rails/activestorage").start()
// require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require('bootstrap/dist/js/bootstrap')
require("@fortawesome/fontawesome-free/js/all")
const moment = require('moment/moment.js')
require('daterangepicker/daterangepicker.js')
require("../stylesheets/application")
require.context("../images/", true)

window.addEventListener('load', (event) => {
  moment.locale('ja');
  $(".datepicker").daterangepicker({
    singleDatePicker: true,
    autoUpdateInput: false,
    locale: {
      daysOfWeek: [
        "日", "月", "火", "水", "木", "金", "土"
      ],
      monthNames: [
        "1月", "2月", "3月",
        "4月", "5月", "6月",
        "7月", "8月", "9月",
        "10月", "11月", "12月"
      ],
      format: 'YYYY-MM-DD'
    }
  });

  $(".datepicker").on("apply.daterangepicker", function(ev, picker) {
    $(this).val(picker.startDate.format("YYYY/MM/DD"));
  });
});
